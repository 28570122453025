import CollapsibleCardHeading from "./Structural Components/EditCardPanel";
import ProjectSelectionHeader from "./ProjectSelectionHeader";
import {Box, Button, CircularProgress, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import GetUserProjects, {DeleteProject, LeaveProject, UpdateProjectName} from "../functions/ServerCallFunctions";
import {useNavigate} from "react-router-dom";
import InlineConfirmationWindow from "./Structural Components/InlineConfirmationWindow";

 export default function ProjectSelectionCollapsable(props) {
    const {project, setProjectErrors } = props;

     function EditComponent({callback}) {

         const [projectDeletePending, setProjectDeletePending] = useState(false);
         const navigate = useNavigate();

         let dateCreated = new Date(project.createdAt).toLocaleDateString() + " " + new Date(project.createdAt).toLocaleTimeString();
         let dateModified = new Date(project.updatedAt).toLocaleDateString() + " " + new Date(project.updatedAt).toLocaleTimeString();

         function ProcessSubmit(data) {
             callback(false)
             onSubmit(data)
         }

         function onSubmit(data) {
             UpdateProjectName(project.projectId, data.projectName).then(r => {
                 GetUserProjects()
             })
         }

         const ValidationSchema = Yup.object().shape({
             projectName: Yup.string()
                 .required('Required'),
         });

         const {register, reset, handleSubmit, formState: {errors}} = useForm({
             defaultValues: {projectName: project.projectName},
             resolver: yupResolver(ValidationSchema),
         });

         function DeleteProjectCallback() {
             setProjectDeletePending(true);
             DeleteProject(project.projectId, project.projectName).then(r => {
                 if (r.code === 200) {
                     navigate(0, {state: {projectDeleted: true, deletedProjectName: project.projectName}});
                 } else {
                     setProjectDeletePending(false);
                 }
             })
         }

         function LeaveProjectCallback() {
             LeaveProject(project.projectId);
         }

         function LeaveEditMenu() {
             reset()
             callback(false)
         }

         const DecideCallback = !project.isOwner ? LeaveProjectCallback : DeleteProjectCallback;

         function LeaveOrDeleteProject(props) {
             return project.isOwner ?
                 <Button color={"error"} onClick={props.callback}>Delete Project</Button> :
                 <Button onClick={props.callback} color={"error"} variant="contained">Leave Project</Button>
         }

         function ProjectDeleteButton(){
             return <InlineConfirmationWindow approvalAction={DecideCallback} DisplayComponent={<LeaveOrDeleteProject/>}>
                 <Typography flexGrow={1} component="div" >
                     {project.isOwner ? "Are you sure you want to delete this project? This action cannot be undone." :
                         "Are you sure you want to leave this project? You will no longer be able to access it."}
                 </Typography>
             </InlineConfirmationWindow>
         }

         if(projectDeletePending) return (<CircularProgress/>)


         return <Box>
             <form onSubmit={handleSubmit(ProcessSubmit)}>
                 <TextField
                     {...register("projectName")}
                     id="outlined-multiline-static"
                     label="Project Name"
                     variant="outlined"
                     disabled={!project.isOwner}
                     sx={{width: "100%"}}
                     defaultValue={project.projectName}
                     error={!!errors.projectName}
                 />
                 <Typography variant={"caption"} color={"error"}>{errors.projectName?.message}</Typography>
                 <Typography variant="body2" sx={{mt: 2}}>
                     Date Created: {dateCreated}
                 </Typography>
                 <Typography variant="body2" sx={{my: 2}}>
                     Date Modified: {dateModified}
                 </Typography>
                 <Box justifyContent={"space-between"} display={"flex"}>
                     <ProjectDeleteButton/>
                     <Button type={"reset"} color={`info`} onClick={LeaveEditMenu}>Cancel</Button>
                     <Button  color={`info`} type={"submit"}>Save</Button>
                 </Box>
             </form>
         </Box>
     }

    return (<CollapsibleCardHeading key={project.projectId}
                                    displayComponent={<ProjectSelectionHeader
                                        ProjectTitle={project.projectName}
                                        ProjectID={project.projectId}
                                        pro={project.isPro}
                                        setProjectErrors={setProjectErrors}
                                        sx={{alignItems: 'center', display: 'flex', p: 1}}
                                        canEdit={project.isOwner}
                                    />}
                                    editComponent={<EditComponent/>}
                                    canEdit={project.isOwner}
    />)
}