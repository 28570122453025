import {Box, IconButton, Stack} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, {useEffect} from "react";


export default function CollapsibleCardHeading(props) {

    const [editMode, setEditMode] = React.useState(false)

    const [canEdit, setCanEdit] = React.useState(true)

    const {displayComponent, editComponent} = props;

    useEffect(() =>{
        if(props.canEdit !== undefined)
        setCanEdit(props.canEdit)
    }, [props.canEdit])

    function editClass() {
        return editMode ? "list-card-container active-card-container" : "list-card-container"
    }

    function EditButton() {
        if(!canEdit) return null
        return <IconButton sx={{
            justifyContent: "flex-end",
        }}
                           onClick={() => setEditMode(true)}><MoreVertIcon fontSize={"large"} color={"info"}/>
        </IconButton>
    }

    function DisplayModeAndToggle() {
        return <Stack sx={{
            maxWidth: "100%",
            flexShrink: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            {displayComponent}
            <EditButton/>
        </Stack>
    }

    return <Box className={editClass()} sx={{display: `inline-flex`, flexWrap: `wrap`, alignItems: `center`}}>
        <Stack sx={{flexGrow: 1, maxWidth: "300px"}}>
            {editMode ? React.cloneElement(editComponent, {callback: setEditMode}) : <DisplayModeAndToggle/>}
        </Stack>
    </Box>
}